import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";


const copyToClipboard = () => {
  const resumeText = document.getElementById('resumeText').innerText;
  let totalText = '';
  const additionalText = document.getElementById('additionalText').value;
  if (additionalText) {
    let middleMessage = '\n____________________________________________________________________\nJIM DEE IS A PERFECT FIT FOR THE FOLLOWING ROLE: \n\n';
    totalText = resumeText + middleMessage + additionalText; 
  } else {
    totalText = resumeText + '\n' + additionalText; 
  }
  navigator.clipboard.writeText(totalText).then(() => {
    alert('Resume text copied to clipboard!');
  }).catch((err) => {
    console.error('Failed to copy text: ', err);
  });
}


const text = `
JIM DEE  
email: Jim@GenerativeNFTs.io 
phone: (503) 891-7153
website: jpd3.com
linkedin: linkedin.com/in/jimdee
github: github.com/jpdnft

____________________________________________________________________

PROFESSIONAL SUMMARY

A passionate full-stack developer with 10+ years of experience in web3, Ethereum smart contracts, front-and back-end development, combined with 10+ years of professional writing, editorial work, and technical content creation. Proven track record of building and nurturing developer communities through educational content, tutorials, and presentations. Adept at simplifying complex technical concepts for diverse audiences and fostering productive dialogues between engineering teams and the developer community.

____________________________________________________________________

TECHNICAL & EDITORIAL SKILLS

• Programming Languages, Tools, & Frameworks: 
  Solidity, React, JSX, JavaScript, PHP, MySQL, HTML, CSS, Hardhat, Node.js, Firebase, GitHub, API integrations, blockchain & web3

• Content Creation: 
  Technical tutorials, video walkthroughs, documentation, presentations, webinars

• Community Engagement: 
  Blogging, podcasts, developer meetups, open-source projects, public speaking

____________________________________________________________________

PROFESSIONAL EXPERIENCE

GenerativeNFTs.io -- Co-Founder & Chief Generative NFT Coder
Location: Remote, Duration: Ongoing

• Co-founded GenerativeNFTs, overseeing the production of ~350,000 generative NFTs with sales exceeding $40 million USD.
• Created technical documentation and tutorials on Ethereum development, which were used by developer communities for onboarding.
• Managed complex international web3 projects -- pipeline management, coding, and team strategy advisory services.
• Deployed 30+ smart contracts on the Ethereum mainnet, contributing to the success of high-profile NFT projects.
• Maintained a popular (1+ million reads) web3/NFT blog, creating 200+ articles on NFT art, teams, strategy, and crypto trends.
• Provided advisory services for NFT drop teams, ensuring multi-million-dollar project sellouts.

Array Web Development -- Principal Web Strategist
Location: Remote, Duration: 2011-2021

• Delivered high-quality web development projects using CMSs, PHP, MySQL, HTML, CSS, and JavaScript, consistently exceeding client expectations.
• Increased ROI for clients through effective digital marketing campaigns, SEO initiatives, and content creation strategies.
• Specialized in custom Joomla! development, including migrations, customizations, and advanced administration.
• Published hundreds of articles to enhance brand awareness and drive traffic, leveraging expertise in web development and digital marketing.

____________________________________________________________________

DEVELOPER RELATIONS / DEVELOPER ADVOCACY QUALIFICATIONS

A good dev needs to be proficient in multiple languages, modalities, frameworks, etc., as the position requires communicating one platform out to devs working in all kinds of environments. So, I'm happy to provide a package of examples from my career, starting with tech qualifications and then covering the tech/editorial side.

In my recent focus within the NFT/web3 space, I've maintained a highly *diverse* overall tech stack, as these projects have tentacles reaching well beyond "NFT" category silo. Smart contracts, for example, are written in Solidity. Then the contract-integrated minting dApps have to be coded in JavaScript / React / JSX, utilizing outside libraries like Ethers.js, and of course heavy in HTML & CSS. My APIs for serving up metadata are done in Node.js leveraging Google Firebase. Step back once more and we get to the art generation coding, which I do using mainly PHP and MySQL. And all of that requires various types of programmatic storage solutions, ranging from Arweave (an IPFS-like blockchain-style permastorage) to serverless platforms like Netlify and Heroku, to old-school web servers. (And Git of course.)

Prior to my being a web3 dev, I spent a decade as an independent web developer. For that role, I did the usual API integrations that web devs do routinely, interfacing with APIs like Youtube, Zoho, and many others. My core customer base was in the Joomla world, which is a CMS framework and front end similar to Wordpress (only with a codebase more suited to business solutions). Doing that, I actually did a ton of "developer advocate" work, educating other devs on everything from the front end to various classes within the codebase itself. 

Typically, developer advocates utilize written articles and tutorials, podcasts, and presentations to facilitate the role's outreach requirements. Here are some examples of my work in this area:


--> DEVELOPER ADVOCACY 1/3:  WRITTEN ARTICLES

Here are ~ 15 articles in the "developer advocate" mode -- ranging from basic instructions on administration to in-depth pieces with code examples. Many of these articles focus on Joomla topics. But, I've thrown in a handful of others showing API integrations, JS library integrations, and more:

'What Is Bootstrap How Can I Use It In Joomla 3'
(https://medium.com/web-design-web-developer-magazine/what-is-bootstrap-how-can-i-use-it-in-joomla-3-669a6a40614a?sk=ab05fbb5376369ad4b64f64e761c4220)

'How To Administer a Joomla Web Site — Part 1: Categorization of Your Content'
(https://medium.com/web-design-web-developer-magazine/how-to-administer-a-joomla-web-site-part-1-categorization-of-your-content-bc9eca0999a?sk=65210bccd138b1e25ce656b6350a96f8)

'How To Administer a Joomla Web Site — Part 2: Creating Articles and Categorizing Them'
(https://medium.com/web-design-web-developer-magazine/how-to-administer-a-joomla-web-site-part-2-creating-articles-and-categorizing-them-a39c081f5f60?sk=d66b2ef4f182566dc3faab01795c4e22)

'How To Administer a Joomla Web Site — Part 3: Menu Administration'
(https://medium.com/web-design-web-developer-magazine/how-to-administer-a-joomla-web-site-part-3-menu-administration-af8427bdd1d5?sk=28b355fe19718e8ace11977fbcadad8a)

'How To Administer a Joomla Web Site — Part 4: Filters and Search Tools'
(https://medium.com/web-design-web-developer-magazine/how-to-administer-a-joomla-web-site-part-4-filters-and-search-tools-92f77d97f8c?sk=21516cb5d6ee0db09163d51ef6bb1416)

'Can I Run My Own PHP Scripts Within Joomla or WordPress Pages'
(https://medium.com/web-design-web-developer-magazine/can-i-run-my-own-php-scripts-within-joomla-or-wordpress-pages-3521ad2e9d6c?sk=5f33cdf1cbb05914264e6b2ea3478a0d)

'What Does Joomla 4 Mean for Your Joomla 3 based Web Site'
(https://medium.com/web-design-web-developer-magazine/what-does-joomla-4-mean-for-your-joomla-3-based-web-site-5f3e814b987?sk=e04d10d9f27c423fa2844c6db6acb6a9)

'How To Integrate Web Sites with YouTube's API A Simple Example Using PHP'
(https://medium.com/web-design-web-developer-magazine/how-to-integrate-web-sites-with-youtubes-api-a-simple-example-using-php-55f308afe6c5?sk=fc0b26d9c7dc552f97ecc5d77b755f4e)

'Leveraging JS File Uploader Libraries in PHP Forms Example using FilePond'
(https://medium.com/web-design-web-developer-magazine/leveraging-js-file-uploader-libraries-in-php-forms-example-using-filepond-754cdc9a8b48?sk=8acdac4ef204d2dc31ffc7bb501b851a)

'Using Arweave Through ArDrive for Storing 10,000 Generative NFTs Permanently How to Get It Done'
(https://medium.com/web-design-web-developer-magazine/using-arweave-through-ardrive-for-storing-10-000-generative-nfts-permanently-how-to-get-it-done-a80ac8e36f30?sk=a8d3e092f43d66fcf62b2f8ca5c2f5c6)

'NFT Teams: Free Yourself from Never-Ending IPFS Pinning Fees: Uploading Images to Arweave via the CLI Method'
(https://medium.com/web-design-web-developer-magazine/nft-teams-free-yourself-from-never-ending-ipfs-pinning-fees-uploading-images-to-arweave-via-the-a94ca51e99f4?sk=1ee94409f42858a96d9c0be638328efd)

'Marketing Tweets using REACH Experimenting With a Platform That Helps Creators Reach Audiences'
(https://medium.com/web-design-web-developer-magazine/marketing-tweets-using-reach-experimenting-with-a-platform-that-helps-creators-reach-audiences-5ef2d76825a3?sk=912cc85db18ccadd645d6eef41906373)

'How To Integrate Web Sites with YouTube's API A Simple Example Using PHP'
(https://medium.com/web-design-web-developer-magazine/how-to-integrate-web-sites-with-youtubes-api-a-simple-example-using-php-55f308afe6c5?sk=fc0b26d9c7dc552f97ecc5d77b755f4e)

'Adding an AI-Generated 3D Spinning Cat to a React Ap Using Meshy.ai and ‘React Three Fiber’. Was It Easy?'
(https://medium.com/web-design-web-developer-magazine/adding-an-ai-generated-3d-spinning-cat-to-a-react-app-using-meshy-ai-4de44aa5d008?sk=ffabee08b3c3b137f97a567514248fe0)

'Implementing a “Hello World” WebSocket App on Heroku'
(https://medium.com/web-design-web-developer-magazine/implementing-a-hello-world-websocket-app-on-heroku-eab85abe08d0?sk=27f7b8ce02b3d62cdd5047f2c396b787)


--> DEVELOPER ADVOCACY 2/3:  PODCAST GUEST

On this podcast, I'm discussing Arweave, a blockchain-type permastorage solution not specific to the NFT industry (but utilized by it):
(https://www.youtube.com/watch?v=zvKRk52M4pI)


--> DEVELOPER ADVOCACY 3/3:  VIDEO / PRESENTATIONS

In the following article you can watch me teach other devs (as a guest speaker at that framework's world conference) how to leverage that framework's database class within their components. Not the greatest video, as I had a cold at that conference! But, also not bad & shows me in "teaching" mode with other devs:

'Leveraging the JDatabase Class in Joomla; A Free Intro Course for Web Developers'
(https://medium.com/web-design-web-developer-magazine/leveraging-the-jdatabase-class-in-joomla-a-free-intro-course-for-web-developers-ddc15a769f2f?sk=5922ce9f07330c210fe62c3ba822b85b)


--> ADDITIONAL ARTICLE

Here's an additional article that discusses my take on Developer Advocate / Developer Relations roles:  'The Unique Role of Developer Advocates in Tech Companies and Organizations'
(https://medium.com/web-design-web-developer-magazine/the-unique-role-of-developer-advocates-in-tech-companies-and-organizations-e653ff5ae1d0?sk=1f95b32199a212a04c19a22b645a2a61)

____________________________________________________________________

EDUCATION

• B.A. English, Wilkes University

____________________________________________________________________

SOCIAL MEDIA AND PORTFOLIO

• Amazon author page: https://amazon.com/author/jimdee
• Facebook author page: https://facebook.com/jpdbooks
• Facebook personal: https://facebook.com/rawfood
• Github: https://github.com/jpdnft
• Instagram: https://instagram.com/SwiggaJuice
• LinkedIn: https://linkedin.com/in/jimdee
• Medium: https://medium.com/@jimdee
• Twitter: https://twitter.com/SwiggaJuice
• Personal website: https://jpd3.com
• Books and writing:  https://jpd3.com/books

____________________________________________________________________

REFERENCES & RECOMMENDATIONS
• See https://www.linkedin.com/in/jimdee/
`;


const ResumeDevAdvocate = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
        <p className="breadcrumbs"><a href="/resume">back to /resume list</a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="#additionalText">add additional info</a></p>

            <h1>DEVELOPER ADVOCATE / DEVELOPER RELATIONS RESUME</h1>
            
            <pre className="preText" id="resumeText" style={{ whiteSpace: 'pre-wrap' }}>{text}</pre>

            <textarea id="additionalText" placeholder="Paste additional information to append to this resume, then use copy link below."></textarea>

            <p><a href="###" onClick={copyToClipboard}>📋 Copy resume plus additional info.</a></p>

            <p className="breadcrumbs"><a href="/resume">back to /resume list</a></p>

        </div>

        <MainFooter />

    </div>
  );
}

export default ResumeDevAdvocate;
